@import './variables';
@import '../../node_modules/spinthatshit/src/loaders.scss';

.loader-spin {
  @include loader01($color: $company-main);
}

.loader-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  align-items: center;
}
