@import 'variables';

.page-item {
  .page-link {
    color: black;
  }
  &.active {
    .page-link {
      background-color: $blue !important;
      border-color: $blue !important;
      color: white !important;
    }
  }
}