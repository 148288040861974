$company-main: #003c71;
$background-gray: #f2f4f5;
$grey-light: #e3e7ec;
$company-dark: #354052;

// $pt-font-size: 10px;
$border-color: #dfe3e9;
$border: 1px solid $border-color;
$content-margin: 2rem;
$grey-lighter: #e3e7ec90;
$dirty-light-blue: #59869b;
$main-gray: #2b3c4a;
$border-radius: 2px;
$light-blue: #005da8;

$blue: #2987d2;
$blueBtn: #2987d2;
$brownBtn: #c2903a;
$textfield-underscore: #737373;

$Mulish: 'Mulish';

:export {
	primaryBlue: $blue;
	textfieldUnderscore: $textfield-underscore;
}
