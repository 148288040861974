@import 'variables';

@font-face {
	font-family: 'Mulish';
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: url('./fonts/Mulish-VariableFont_wght.ttf');
}

@font-face {
	font-family: 'Mulish';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url('./fonts/Mulish-VariableFont_wght.ttf');
}

@font-face {
	font-family: 'Mulish';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('./fonts/Mulish-VariableFont_wght.ttf');
}

body {
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body {
	margin: 0;
	font-size: 14px;
	overflow-x: hidden;
	font-family: 'Mulish', sans-serif;
}

input,
select,
textarea,
button {
	box-shadow: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $Mulish;
}

#root {
	display: flex;
	flex: 1;
	justify-content: space-between;
	flex-direction: column;
	height: 100vh;
}

.eq-router-container {
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
}

.no-top-margin {
	margin-top: 0;
}

.btn-light {
	color: $light-blue !important;
	background-color: white !important;
	border: $border !important;
}

.modal-header {
	border-bottom: none !important;
}

.modal-content {
	border-radius: 0 !important;
}

.modal-body {
	padding: 2rem !important;
}

.modal-header .close {
	color: $company-main;
	opacity: 1;
}

.modal-header {
	padding-left: 2rem !important;
	padding-right: 2rem !important;
	padding-bottom: 0 !important;
	padding-top: 2rem !important;
}

.error-text {
	color: #f86c6b;
}

p {
	margin-bottom: 0.5rem !important;
}

.btn {
	border-radius: $border-radius !important;
	font-family: 'BebasNeue';
}

.form-control {
	border-radius: $border-radius !important;
}

.input-group-text {
	border-radius: $border-radius !important;
}

.btn-primary {
	background-color: $blueBtn !important;
	border-color: $blueBtn !important;
}

.light-blue {
	color: $light-blue;
}

.pointer {
	cursor: pointer;
}

.blue {
	background-color: $blueBtn !important;
}

.blue-border {
	border: 2px solid $blue !important;
}

.transparent {
	background-color: transparent !important;
}

.st-b-r {
	border-radius: 2px !important;
}

.no-border {
	border: none !important;
}

.button {
	font-family: 'Mulish' !important;
}

.black-hover:hover {
	color: black !important;
}

//datepicker
.rdt {
	position: relative;
}
.rdtPicker {
	display: none;
	position: absolute;
	width: 250px;
	padding: 4px;
	margin-top: 1px;
	z-index: 99999 !important;
	background: #fff;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	border: 1px solid #f9f9f9;
}

@media screen and (max-width: 416px) {
	.rdtPicker {
		width: 100%;
	}
}
.rdtOpen .rdtPicker {
	display: block;
}
.rdtStatic .rdtPicker {
	box-shadow: none;
	position: static;
}

.rdtPicker .rdtTimeToggle {
	text-align: center;
}

.rdtPicker table {
	width: 100%;
	margin: 0;
}
.rdtPicker td,
.rdtPicker th {
	text-align: center;
	height: 28px;
}
.rdtPicker td {
	cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
	background: #eeeeee;
	cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
	color: #999999;
}
.rdtPicker td.rdtToday {
	position: relative;
}
.rdtPicker td.rdtToday:before {
	content: '';
	display: inline-block;
	border-left: 7px solid transparent;
	border-bottom: 7px solid #428bca;
	border-top-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	bottom: 4px;
	right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
	background-color: #428bca;
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
	border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
	color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}
.rdtPicker th {
	border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
	width: 14.2857%;
	border-bottom: none;
	cursor: default;
}
.rdtPicker th.rdtSwitch {
	width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
	font-size: 21px;
	vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
	display: block;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-khtml-user-select: none; /* Konqueror */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
	background: none;
	color: #999999;
	cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
	cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
	background: #eeeeee;
}

.rdtPicker tfoot {
	border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
	border: none;
	background: none;
	cursor: pointer;
}
.rdtPicker button:hover {
	background-color: #eee;
}

.rdtPicker thead button {
	width: 100%;
	height: 100%;
}

td.rdtMonth,
td.rdtYear {
	height: 50px;
	width: 25%;
	cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
	background: #eee;
}

.rdtCounters {
	display: inline-block;
}

.rdtCounters > div {
	float: left;
}

.rdtCounter {
	height: 100px;
}

.rdtCounter {
	width: 40px;
}

.rdtCounterSeparator {
	line-height: 100px;
}

.rdtCounter .rdtBtn {
	height: 40%;
	line-height: 40px;
	cursor: pointer;
	display: block;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-khtml-user-select: none; /* Konqueror */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}
.rdtCounter .rdtBtn:hover {
	background: #eee;
}
.rdtCounter .rdtCount {
	height: 20%;
	font-size: 1.2em;
}

.rdtMilli {
	vertical-align: middle;
	padding-left: 8px;
	width: 48px;
}

.rdtMilli input {
	width: 100%;
	font-size: 1.2em;
	margin-top: 37px;
}

.rdtTime td {
	cursor: default;
}

//end datepicker
.dropdown-menu {
	border-radius: 5px !important;
	padding-left: 10px !important;
}

.alert {
	position: fixed !important;
	width: 100%;
	top: 0;
	z-index: 100;
}

.load {
	display: inline-block;
	width: 30px;
	height: 30px;
	border: 5px solid #ffff;
	border-radius: 100%;
	animation: load-animate infinite linear 1s;
}

@keyframes load-animate {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
		opacity: 0.35;
	}
	75% {
		transform: rotate(360deg);
	}
}

.labelSize {
	font-size: 13px;
	margin-bottom: 0px;
}

@media screen and (max-width: 416px) {
	.padding-for-mobile {
		padding: 10px;
	}
}

.dropdown-menu.show {
	transform: translate3d(-34px, 30px, 0px) !important;
}

.CookieConsent {
	align-items: start !important;
}

.cookie-buttons-container {
	display: flex;
	flex-direction: column-reverse;
	padding-bottom: 15px;
	padding-right: 15px;
}
